import { default as indexB7wu93QXtVMeta } from "/app/apps/app/app/pages/advisor/billing/index.vue?macro=true";
import { default as indexoIutLpRKDpMeta } from "/app/apps/app/app/pages/advisor/billing/invoices/[invoice_id]/index.vue?macro=true";
import { default as indexEmnayLTKZcMeta } from "/app/apps/app/app/pages/advisor/billing/invoices/index.vue?macro=true";
import { default as activitiesrrYJXz8CmnMeta } from "/app/apps/app/app/pages/advisor/community-service/activities.vue?macro=true";
import { default as indexKeNXTIATdYMeta } from "/app/apps/app/app/pages/advisor/community-service/index.vue?macro=true";
import { default as membersbUmETQHVlAMeta } from "/app/apps/app/app/pages/advisor/community-service/members.vue?macro=true";
import { default as community_45serviceODFuP2AURmMeta } from "/app/apps/app/app/pages/advisor/community-service.vue?macro=true";
import { default as conferencescFcxf9sBASMeta } from "/app/apps/app/app/pages/advisor/conferences.vue?macro=true";
import { default as indexF4R0RJ9NdtMeta } from "/app/apps/app/app/pages/advisor/index.vue?macro=true";
import { default as inactivequTIJgcEciMeta } from "/app/apps/app/app/pages/advisor/members/inactive.vue?macro=true";
import { default as indexqhkOpCY07eMeta } from "/app/apps/app/app/pages/advisor/members/index.vue?macro=true";
import { default as membersSiZIgZlpXVMeta } from "/app/apps/app/app/pages/advisor/members.vue?macro=true";
import { default as advisorQ644Ks3KzQMeta } from "/app/apps/app/app/pages/advisor.vue?macro=true";
import { default as indexKHZO9M4UDmMeta } from "/app/apps/app/app/pages/index.vue?macro=true";
import { default as loginVq6iOkF5EfMeta } from "/app/apps/app/app/pages/login.vue?macro=true";
import { default as logouthDp7oxl0OYMeta } from "/app/apps/app/app/pages/logout.vue?macro=true";
import { default as requestWC3E5K9pISMeta } from "/app/apps/app/app/pages/password/request.vue?macro=true";
import { default as resetorzWF4EXXPMeta } from "/app/apps/app/app/pages/password/reset.vue?macro=true";
import { default as indexGfN9K5MUUYMeta } from "/app/apps/app/app/pages/staff/index.vue?macro=true";
import { default as advisorsLbzTPZnQakMeta } from "/app/apps/app/app/pages/staff/regions/[region_id]/advisors.vue?macro=true";
import { default as indexPpm9YMLTESMeta } from "/app/apps/app/app/pages/staff/regions/[region_id]/index.vue?macro=true";
import { default as _91region_id_93w6wDEULn89Meta } from "/app/apps/app/app/pages/staff/regions/[region_id].vue?macro=true";
import { default as regionsJYBQWiBVXVMeta } from "/app/apps/app/app/pages/staff/regions.vue?macro=true";
import { default as staffH6gDFgFBoqMeta } from "/app/apps/app/app/pages/staff.vue?macro=true";
import { default as verifyjzTNtiB0SLMeta } from "/app/apps/app/app/pages/verify.vue?macro=true";
export default [
  {
    name: advisorQ644Ks3KzQMeta?.name,
    path: "/advisor",
    meta: advisorQ644Ks3KzQMeta || {},
    component: () => import("/app/apps/app/app/pages/advisor.vue").then(m => m.default || m),
    children: [
  {
    name: "advisor-billing",
    path: "billing",
    meta: indexB7wu93QXtVMeta || {},
    component: () => import("/app/apps/app/app/pages/advisor/billing/index.vue").then(m => m.default || m)
  },
  {
    name: "advisor-billing-invoices-invoice_id",
    path: "billing/invoices/:invoice_id()",
    meta: indexoIutLpRKDpMeta || {},
    component: () => import("/app/apps/app/app/pages/advisor/billing/invoices/[invoice_id]/index.vue").then(m => m.default || m)
  },
  {
    name: "advisor-billing-invoices",
    path: "billing/invoices",
    meta: indexEmnayLTKZcMeta || {},
    component: () => import("/app/apps/app/app/pages/advisor/billing/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: community_45serviceODFuP2AURmMeta?.name,
    path: "community-service",
    meta: community_45serviceODFuP2AURmMeta || {},
    component: () => import("/app/apps/app/app/pages/advisor/community-service.vue").then(m => m.default || m),
    children: [
  {
    name: "advisor-community-service-activities",
    path: "activities",
    meta: activitiesrrYJXz8CmnMeta || {},
    component: () => import("/app/apps/app/app/pages/advisor/community-service/activities.vue").then(m => m.default || m)
  },
  {
    name: "advisor-community-service",
    path: "",
    meta: indexKeNXTIATdYMeta || {},
    component: () => import("/app/apps/app/app/pages/advisor/community-service/index.vue").then(m => m.default || m)
  },
  {
    name: "advisor-community-service-members",
    path: "members",
    meta: membersbUmETQHVlAMeta || {},
    component: () => import("/app/apps/app/app/pages/advisor/community-service/members.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "advisor-conferences",
    path: "conferences",
    meta: conferencescFcxf9sBASMeta || {},
    component: () => import("/app/apps/app/app/pages/advisor/conferences.vue").then(m => m.default || m)
  },
  {
    name: "advisor",
    path: "",
    meta: indexF4R0RJ9NdtMeta || {},
    component: () => import("/app/apps/app/app/pages/advisor/index.vue").then(m => m.default || m)
  },
  {
    name: membersSiZIgZlpXVMeta?.name,
    path: "members",
    meta: membersSiZIgZlpXVMeta || {},
    component: () => import("/app/apps/app/app/pages/advisor/members.vue").then(m => m.default || m),
    children: [
  {
    name: "advisor-members-inactive",
    path: "inactive",
    meta: inactivequTIJgcEciMeta || {},
    component: () => import("/app/apps/app/app/pages/advisor/members/inactive.vue").then(m => m.default || m)
  },
  {
    name: "advisor-members",
    path: "",
    meta: indexqhkOpCY07eMeta || {},
    component: () => import("/app/apps/app/app/pages/advisor/members/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexKHZO9M4UDmMeta || {},
    component: () => import("/app/apps/app/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginVq6iOkF5EfMeta || {},
    component: () => import("/app/apps/app/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "logout",
    path: "/logout",
    meta: logouthDp7oxl0OYMeta || {},
    component: () => import("/app/apps/app/app/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: "password-request",
    path: "/password/request",
    meta: requestWC3E5K9pISMeta || {},
    component: () => import("/app/apps/app/app/pages/password/request.vue").then(m => m.default || m)
  },
  {
    name: "password-reset",
    path: "/password/reset",
    meta: resetorzWF4EXXPMeta || {},
    component: () => import("/app/apps/app/app/pages/password/reset.vue").then(m => m.default || m)
  },
  {
    name: staffH6gDFgFBoqMeta?.name,
    path: "/staff",
    meta: staffH6gDFgFBoqMeta || {},
    component: () => import("/app/apps/app/app/pages/staff.vue").then(m => m.default || m),
    children: [
  {
    name: "staff",
    path: "",
    meta: indexGfN9K5MUUYMeta || {},
    component: () => import("/app/apps/app/app/pages/staff/index.vue").then(m => m.default || m)
  },
  {
    name: "staff-regions",
    path: "regions",
    meta: regionsJYBQWiBVXVMeta || {},
    component: () => import("/app/apps/app/app/pages/staff/regions.vue").then(m => m.default || m),
    children: [
  {
    name: _91region_id_93w6wDEULn89Meta?.name,
    path: ":region_id()",
    meta: _91region_id_93w6wDEULn89Meta || {},
    component: () => import("/app/apps/app/app/pages/staff/regions/[region_id].vue").then(m => m.default || m),
    children: [
  {
    name: "staff-regions-region_id-advisors",
    path: "advisors",
    meta: advisorsLbzTPZnQakMeta || {},
    component: () => import("/app/apps/app/app/pages/staff/regions/[region_id]/advisors.vue").then(m => m.default || m)
  },
  {
    name: "staff-regions-region_id",
    path: "",
    meta: indexPpm9YMLTESMeta || {},
    component: () => import("/app/apps/app/app/pages/staff/regions/[region_id]/index.vue").then(m => m.default || m)
  }
]
  }
]
  }
]
  },
  {
    name: "verify",
    path: "/verify",
    meta: verifyjzTNtiB0SLMeta || {},
    component: () => import("/app/apps/app/app/pages/verify.vue").then(m => m.default || m)
  }
]
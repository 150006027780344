import revive_payload_client_aRB6GiRKLt from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_fybq34l4zq57yx5n4lyhih2p6e/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_pkGg7p6Zts from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_fybq34l4zq57yx5n4lyhih2p6e/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_TnHY5kLBo2 from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_fybq34l4zq57yx5n4lyhih2p6e/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_XQ7NydLCS5 from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_fybq34l4zq57yx5n4lyhih2p6e/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_a4Ln1ycKpp from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_fybq34l4zq57yx5n4lyhih2p6e/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_bSkZ3f9cNb from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_fybq34l4zq57yx5n4lyhih2p6e/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_oPRod0Rj2b from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_fybq34l4zq57yx5n4lyhih2p6e/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_4tBTIsGc8D from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.3_magicast@0.3.4_rollup@4.20.0_typescript@5.5.4_vue@3.4.38_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/apps/app/.nuxt/components.plugin.mjs";
import prefetch_client_D445nELmi9 from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_fybq34l4zq57yx5n4lyhih2p6e/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import session_client_jDPMr7L2B4 from "/app/node_modules/.pnpm/nuxt-auth-utils@0.3.4_magicast@0.3.4_rollup@4.20.0/node_modules/nuxt-auth-utils/dist/runtime/app/plugins/session.client.js";
import slideovers_cBURJhmBkH from "/app/node_modules/.pnpm/@nuxt+ui@2.18.4_axios@1.7.4_magicast@0.3.4_rollup@4.20.0_ts-node@10.9.2_@swc+core@1.3.101_@sw_swp3n2wzxqnhxr55euhjerv5tm/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_DTvcptyJih from "/app/node_modules/.pnpm/@nuxt+ui@2.18.4_axios@1.7.4_magicast@0.3.4_rollup@4.20.0_ts-node@10.9.2_@swc+core@1.3.101_@sw_swp3n2wzxqnhxr55euhjerv5tm/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_m7u7uC6bEl from "/app/node_modules/.pnpm/@nuxt+ui@2.18.4_axios@1.7.4_magicast@0.3.4_rollup@4.20.0_ts-node@10.9.2_@swc+core@1.3.101_@sw_swp3n2wzxqnhxr55euhjerv5tm/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_tP6NBBbDCP from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.4.4_magicast@0.3.4_rollup@4.20.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_U07HeJLMXW from "/app/node_modules/.pnpm/@nuxt+icon@1.4.5_magicast@0.3.4_rollup@4.20.0_vite@5.4.1_@types+node@22.4.0_terser@5.31.6__vue@3.4.38_typescript@5.5.4_/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_ccYgkBwMs5 from "/app/node_modules/.pnpm/@nuxt+ui-pro@1.4.1_axios@1.7.4_magicast@0.3.4_rollup@4.20.0_ts-node@10.9.2_@swc+core@1.3.101__wh5mzzirhlxtbh74tlziolc5ju/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import presets_aVNj9gd4XJ from "/app/node_modules/.pnpm/@nuxt+ui-pro@1.4.1_axios@1.7.4_magicast@0.3.4_rollup@4.20.0_ts-node@10.9.2_@swc+core@1.3.101__wh5mzzirhlxtbh74tlziolc5ju/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_G77rQxVj5G from "/app/node_modules/.pnpm/@nuxt+ui-pro@1.4.1_axios@1.7.4_magicast@0.3.4_rollup@4.20.0_ts-node@10.9.2_@swc+core@1.3.101__wh5mzzirhlxtbh74tlziolc5ju/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import sentry_client_a4hAB0MOGi from "/app/apps/app/app/plugins/sentry.client.ts";
export default [
  revive_payload_client_aRB6GiRKLt,
  unhead_pkGg7p6Zts,
  router_TnHY5kLBo2,
  payload_client_XQ7NydLCS5,
  navigation_repaint_client_a4Ln1ycKpp,
  check_outdated_build_client_bSkZ3f9cNb,
  chunk_reload_client_oPRod0Rj2b,
  plugin_vue3_4tBTIsGc8D,
  components_plugin_KR1HBZs4kY,
  prefetch_client_D445nELmi9,
  session_client_jDPMr7L2B4,
  slideovers_cBURJhmBkH,
  modals_DTvcptyJih,
  colors_m7u7uC6bEl,
  plugin_client_tP6NBBbDCP,
  plugin_U07HeJLMXW,
  scrollbars_client_ccYgkBwMs5,
  presets_aVNj9gd4XJ,
  variables_G77rQxVj5G,
  sentry_client_a4hAB0MOGi
]
import routerOptions0 from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_fybq34l4zq57yx5n4lyhih2p6e/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/app/node_modules/.pnpm/@nuxt+ui-pro@1.4.1_axios@1.7.4_magicast@0.3.4_rollup@4.20.0_ts-node@10.9.2_@swc+core@1.3.101__wh5mzzirhlxtbh74tlziolc5ju/node_modules/@nuxt/ui-pro/modules/pro/runtime/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}